<template>
  <div class='d-flex flex-column'>
    <div class='d-flex flex-row justify-space-around align-center'>
      <v-btn
        color='accent'
        @click='generateData()'
      >
        Generate data
      </v-btn>
      <v-switch
        v-model='showData'
        label='Show data'
      ></v-switch>
      <v-switch
        v-model="useOpacity"
        label="Opacity"
      ></v-switch>
      <v-switch
        :disabled="!useOpacity"
        v-model="opacityInverted"
        label="Inverted"
      ></v-switch>
      <v-text-field
        class="pr-3 width300"
        label="Amount"
        v-model.number="tiles[0].value"
        type="number"
        step="120"
      ></v-text-field>
      <v-slider
        class="width300"
        min="0"
        max="100"
        v-model="tiles[0].percentage"
        thumb-size="20"
        thumb-label="always"
        label="%"
      ></v-slider>
    </div>
    <div class='d-flex flex-column mb-6'>
      <div
        class='d-flex flex-row align-center'
        v-for='(_, i) in tiles'
        :key='i'
      >
        <bar-value-tile
          class="ma-2"
          :dontUseOpacity="!useOpacity"
          :opacityInverted="opacityInverted"
          :value="_.value"
          :isAmount="_.isAmount"
          currency="CAD"
          :label="_.label"
          :percentage="_.percentage"
          :bar-color="_.color"
        />
        <span
          v-if='showData'
          class='data ml-10'
        >{{_}}</span>
      </div>

    </div>

  </div>

</template>

<script>

import tc from 'tinycolor2'
import _ from 'lodash'

export default {
  components: {
    BarValueTile: () => import('@/components/bar-value-tile')
  },
  created () {
    this.generateData()
  },
  data () {
    return {
      showData: false,
      useOpacity: true,
      opacityInverted: false,
      tiles: []
    }
  },
  methods: {
    generateData () {
      this.tiles = []
      let i
      for (i = 1; i <= 8; i++) {
        const isAmount = _.sample([true, false])
        this.tiles.push({
          id: i,
          label: `Label ${i}`,
          isAmount: isAmount,
          value: isAmount ? _.random(99000000) : _.random(99999),
          percentage: _.random(1, 100),
          color: tc.random().toHexString()
        })
      }
    }
  }

}
</script>

<style lang='stylus' scoped>
.data
  color silver
  font-size 0.8em

.width300
  max-width 300px
</style>
